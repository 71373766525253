import React from 'react'

const TalosLogo = ({logoColor, noFish, className}) => {
  return (
    <svg
      width="100%"
      fill="none"
      viewBox="0 0 1280 500"
      preserveAspectRatio="xMinYMax"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      {!noFish && 
        <path
          d="M912.381 161.588l3.373 57.429-9.953.586-2.46-41.899-22.464 22.5 25.378 25.419-7.049 7.061-25.379-25.419-7.05 7.061 25.379 25.419-7.05 7.061-25.378-25.42-7.755 7.767 25.379 25.42-7.05 7.061-25.379-25.42-7.581 7.594 2.981 50.76-9.952.586-2.853-48.567-48.49-2.857.585-9.969 50.679 2.986 7.582-7.594-25.379-25.419 7.05-7.061 25.378 25.42 7.755-7.767-25.379-25.42 7.05-7.061 25.379 25.42 7.049-7.061-25.378-25.419 7.049-7.061 25.379 25.419 22.464-22.5-41.832-2.465.585-9.968 57.337 3.378z"
          fill={logoColor || "#131F26"}
        />
      }
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1071.16 220.576c0 121.82-97.469 220.575-217.705 220.575-120.236 0-217.707-98.755-217.707-220.575S733.219 0 853.455 0c120.236 0 217.705 98.755 217.705 220.576zm-26.43 0c0 107.489-85.92 194.106-191.275 194.106-105.356 0-191.28-86.617-191.28-194.106 0-107.49 85.924-194.107 191.28-194.107 105.355 0 191.275 86.617 191.275 194.107z"
        fill={logoColor || "#131F26"}
      />
      <path
        d="M69.306 435.468h26.75V33.578h69.307V9.22H0v24.357h69.306v401.89z"
        fill={logoColor || "#131F26"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.536 435.468h-26.75L291.803 7.394h29.181l93.017 428.074h-27.966l-24.926-119.349H250.462l-24.926 119.349zm80.25-386.058l-50.46 242.352h100.92L305.786 49.41z"
        fill={logoColor || "#131F26"}
      />
      <path
        d="M501.641 435.468H635.39v-24.357H528.391V9.221h-26.75v426.247zM1280 351.436c0 54.194-37.09 88.903-86.33 88.903-24.93 0-48.03-7.916-73.56-22.53v-30.446c29.18 18.876 53.5 27.401 74.17 27.401 35.87 0 58.97-23.748 58.97-61.501v-1.218c0-35.317-12.77-57.848-59.58-121.176-56.54-76.115-70.52-99.254-70.52-138.226v-1.217c0-50.541 37.08-87.077 83.29-87.077 24.32 0 43.77 6.698 64.44 18.877v30.446c-23.1-15.832-44.38-23.748-65.05-23.748-32.83 0-55.93 24.357-55.93 59.675v1.218c0 31.664 12.77 54.803 60.19 115.086 51.67 65.155 69.91 98.646 69.91 144.315v1.218zM196.569 477.312h221.482V500H196.569v-22.688z"
        fill={logoColor || "#131F26"}
      />
    </svg>
  );
}

export default TalosLogo
