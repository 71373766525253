import React from 'react'

const MultiArrow = ({arrowColor}) => 
<svg width="1.65em" height="0.75em" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 5H21" stroke={arrowColor || "#FF4C00"} strokeWidth="1.25" strokeLinecap="round"/>
<path d="M17 1L21 5L17 9" stroke={arrowColor || "#FF4C00"} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13 1L17 5L13 9" stroke={arrowColor || "#FF4C00"} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9 1L13 5L9 9" stroke={arrowColor || "#FF4C00"} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export default MultiArrow
