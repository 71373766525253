import React from 'react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../hooks/use-site-metadata'


const Seo = ({meta}) => {
  const { siteURL } = useSiteMetadata();
  
  const pageTitle = meta.title || "Talos Ventures"
  const pageDescription = meta.description || "We are a multi-stage healthcare investment firm dedicated to investing in founders solving hard problems."
  const seoImage = siteURL + meta.image || siteURL + "/img/seo-image-1.png"

  return (
  <Helmet>
    <title>Talos Ventures</title>
    <meta name="title" content={pageTitle} />
    <meta name="description" content={pageDescription} />

    <meta property="og:type" content="website" />
    <meta property="og:url" content={siteURL} />
    <meta property="og:title" content={pageTitle} />
    <meta property="og:description" content={pageDescription} />
    <meta property="og:image" content={seoImage} />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content={siteURL} />
    <meta property="twitter:title" content={pageTitle} />
    <meta property="twitter:description" content={pageDescription} />
    <meta property="twitter:image" content={seoImage} />
  </Helmet>
)}

export default Seo